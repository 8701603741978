
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import { ROOT_ACTIONS } from '@/store/actions';
import { User } from '@/store/user/types';
import { USER_GETTERS } from '@/store/user/getters';
import { Timestamp, addDoc, collection, doc } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { parse, format, subMinutes } from 'date-fns';

@Component
export default class AddActualActivityDialog extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ default: '', required: true }) activity!: string;

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  step = 1;
  loading = false;

  inputs = {
    hours: 1,
    minutes: 0,
    comment: '',
    date: format(subMinutes(new Date(), new Date().getTimezoneOffset()), 'yyyy-MM-dd'),
    time: '08:00',
    participantPresent: true
  }

  resetFields() {
    this.inputs = {
      hours: 1,
      minutes: 0,
      comment: '',
      date: format(subMinutes(new Date(), new Date().getTimezoneOffset()), 'yyyy-MM-dd'),
      time: '08:00',
      participantPresent: true
    }
  }

  async submit() {
    try {
      this.loading = true;
      const pd = parse(`${this.inputs.date} ${this.inputs.time}`, 'yyyy-MM-dd HH:mm', new Date());

      await addDoc(collection(firestore, 'actual-activities'), {
        actualHours: parseFloat(this.inputs.hours + (this.inputs.minutes / 60) as unknown as string),
        comment: this.inputs.comment,
        activityId: this.activity,
        date: Timestamp.fromDate(pd),
        participantConfirmation: false,
        participantPresent: this.inputs.participantPresent,
      })

      this.show = false;
      this.$emit('refresh')
      this.resetFields();
      this.step = 1;

    } catch (error: any) {
      console.log(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading = false;
    }
  }
}
